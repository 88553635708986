/** @format */

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: Roboto;
}

#nprogress .bar {
  top: 60px !important;
}

#nprogress .peg {
  opacity: 0 !important;
}
